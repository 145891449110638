@import 'global';

.background1 {
  background: $color-green;
}

.logo {
  margin: 5px 10px;
}

.background-circle-change {
  background: $color-white;
  clip-path: circle(230px at 230px 230px);
  height: 480px;
  width: 460px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
  
}

.login-change-password {
  align-items: center;
  justify-content: center;
}

.login-change-password form {
  position: absolute;
  text-align: center;
  width: 330px;  
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.cheque {
  align-items: center;
  background: $color-pink;
  border: 1px;
  bottom: 4.17%;
  clip-path: circle(19px at 19px 19px);
  color: $color-white;
  font-size: 26px;
  height: 39px;
  left: 4.17%;
  line-height: 40px;
  right: 4.17%;
  top: 4.17%;
  width: 39px;
}

.envio {
  margin-top: 8px;
}

.btn-enviado {
  align-items: center;
  background: $color-green;
  border: 1px;
  bottom: 4.17%;
  clip-path: circle(19px at 19px 19px);
  color: $color-white;
  height: 39px;
  left: 4.17%;
  line-height: 40px;
  right: 4.17%;
  top: 4.17%;
  width: 39px;
}

.no-success {
  display: none;
}

.return {
  left: 20px;
  position: absolute;
  top: 590px;
}

.fas {
  color: $color-white;
  font-size: 30px;
}

/*
@media (min-width: 1040px) and (max-width: 1200px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 290px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 405px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 990px) and (max-width: 1040px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 220px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 335px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 870px) and (max-width: 990px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 200px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 320px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 670px) and (max-width: 870px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 150px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 265px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

.flex-shrink {
  margin-top: -8px;
}

@media (min-width: 638px) and (max-width: 738px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 220;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 335px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 548px) and (max-width: 638px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 200px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 145px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 450px) and (max-width: 548px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: -20px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 100px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 150px) and (max-width: 450px) {
  .background-circle-change {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: -92px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-change-password form {
    left: 30px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}
*/
