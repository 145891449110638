.swal2-header{
    font-size: 13px;
    z-index: 500000000000000;
}

.swal2-title{
    font-family: "Open Sans", "Helvetica", "Arial", "serif" ;
    text-align: right;
    font-weight: 200;
    line-height: normal;
    z-index: 500000000000000;
}

.swal2-container.swal2-top-end.swal2-backdrop-show{
    padding-top: 120px;
    padding-right: 33px;
    max-width: 800px;
    z-index: 500000000000000;
}
.swal2-popup.swal2-toast.swal2-show{
    z-index: 500000000000000;
}
.swal2-icon.swal2-warning::before {
    content: '' !important;
    z-index: 500000000000000;
}