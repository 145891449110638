.icon {
    height: 40px;
    width: 40px;
  }
  
  .button {
    background: transparent;
    outline: none;
    border: none;
  }

  .react-emoji-picker--wrapper {
    position: absolute;
    top: 10px !important; 
    right: 0;
    height: 357px;
    width: 388px !important;
    overflow: hidden;
    z-index: 10;
}
.count-text{
  text-align: end;
  font-size: 13px;
  font-weight: bold;
}