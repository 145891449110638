@import 'global';

.navbar-icon-top .navbar-nav .nav-link>.fa {
  font-size: 24px;
  position: relative;
  width: 36px;
}

.form-inline {
  display: -ms-flexbox;
  float: right;
  margin: auto;
}

.header-menu {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.navbar-icon-top .navbar-nav .nav-link>.fa>.badge {
  font-size: .75rem;
  position: absolute;
  right: 0;
}

.navbar-icon-top .navbar-nav .nav-link>.fa {
  line-height: 12px;
  top: 3px;
}

.bg-light-collapse {
  background-color: $color-white !important;
  margin-left: 130px;
  z-index: 3;
}

.bg-light {
  background-color: $color-white !important;
  margin-left: 150px;
  z-index: 3;
}

.bg-light-in {
  background-color: $color-white !important;
  margin-left: -110px;
}

#img-user {
  border-radius: 16px;
  border-style: none;
  height: 60px;
  vertical-align: baseline;
  width: 60px;
}

.profile {
  display: inline;
  line-height: 300px;
  margin-left: 20px;
  vertical-align: middle;
  width: 200px;
}

.navbar-dark .navbar-nav .nav-link {
  color: $color-black;
}

.navbar-icon-top .navbar-nav .nav-link>.fa>.badge {
  top: -10px;
}


.rigth-menu {
  bottom: 25%;
  position: absolute;
  right: 100px;
  top: 50%;
}

.collapseButton {
  cursor: pointer;
}

.navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link {
  display: table-cell;
  height: 70px;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
  vertical-align: middle;
}

.navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fa {
  display: block;
  line-height: 24px;
  margin: 2px auto 4px auto;
  top: 0;
  width: 48px;
}

.navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fa>.badge {
  top: -7px;
}

@media (min-width: 1200px) {
  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link {
    display: table-cell;
    height: 70px;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
    vertical-align: middle;
  }

  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fa {
    display: block;
    line-height: 24px;
    margin: 2px auto 4px auto;
    top: 0;
    width: 48px;
  }

  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link>.fa>.badge {
    top: -7px;
  }
}

@media (min-width: 992px) {
  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link {
    display: table-cell;
    height: 70px;
    padding-bottom: 0;
    padding-top: 0;
    vertical-align: middle;
  }

  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link>.fa {
    display: block;
    line-height: 24px;
    margin: 2px auto 4px auto;
    top: 0;
    width: 48px;
  }

  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link>.fa>.badge {
    top: -7px;
  }

}


@media (min-width: 576px) {
  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link {
    display: table-cell;
    height: 70px;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
    vertical-align: middle;
  }

  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link>.fa {
    display: block;
    line-height: 24px;
    margin: 2px auto 4px auto;
    top: 0;
    width: 48px;
  }

  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link>.fa>.badge {
    top: -7px;
  }
}

@media (min-width: 768px) {
  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link {
    display: table-cell;
    height: 70px;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
    vertical-align: middle;
  }

  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link>.fa {
    display: block;
    line-height: 24px;
    margin: 2px auto 4px auto;
    top: 0;
    width: 48px;
  }

  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link>.fa>.badge {
    top: -7px;
  }
}




