.content-criterio{
    margin-top: 2%;
    border-top: 1px solid #cccccc;
}
#errorListas{text-align: center;}
.sidebar-box select{
    display:block;
    padding: 5px 10px;
    min-width: 225px;
    -webkit-appearance: none;
    // height: 38px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}
.form-group, #errorTipoCanal{
    text-align: left;
}
.delete-criterio{
    text-align: right;
}
.notsend{
    display: none;
}
.form-label{
    font-weight: bold;
}
.content-data{
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    border-bottom: 1px solid #cccccc;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.agrega-criterio{
    margin-top: 2%;
    width: 30%;
    cursor: pointer;
}
.agrega-criterio span{
    color:#B1B4C9;
}
#content2{
    .page-item{
        &.disabled {
            .page-link{
                i{
                    color: #ff419757;
                }
            } 
        }
        .page-link{
            i{
                color: #ff4197;
            }
        }
    }
    
}
.header-all-group{
    text-align: left;
}
.table-create-segmento{
    .table{
        font-size: 13px;
    }
}