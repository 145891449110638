.MuiDialog-paperWidthSm {
    width: 900px !important; 
   }
.bad-doc{
    border: 1px solid #ff4197;
    border-style: dashed;
}
.docs-excel{

}
.card {
    cursor: pointer;
}
.areaQuitDoc{
    position: absolute;
    right: 0%;
    top: -30%;
}
.getDocContacts{
    height: 100%;
}
textarea.form-control.carga-contacto-text{
    height: 150px;
}
.colum{
    span{
        font-size: 12px;
    }
}
