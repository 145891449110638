.container-sms {
    width: 400px;
    padding: 10px;
}

.message-blue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #FDF53F;
    width: 200px;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #FDF53F;
    border-radius: 10px;
}

.message-orange {
    position: relative;
    margin-bottom: 10px;
    margin-left: calc(100% - 240px);
    padding: 10px;
    background-color: #f8e896;
    width: 200px;
    height: 50px;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #dfd087;
    border-radius: 10px;
}

.tilteId { 
  align-items: baseline; 
  justify-content: center; 
} 

.message-content {
    padding: 0;
    margin: 0;
}

.message-timestamp-right {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    right: 5px;
}

.message-timestamp-left {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    left: 5px;
}

.message-blue:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #FDF53F;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
}

.message-blue:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #FDF53F;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    left: -17px;
}

.message-orange:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 15px solid #f8e896;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
}

.message-orange:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 17px solid #dfd087;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    bottom: -1px;
    right: -17px;
}

  /* User Bar */
  
 
  .user-bar-sms{
    height: 55px;
    background: #F2AB08;
    color: #fff;
    padding: 0 8px;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }
  
  .user-bar-sms:after {
    content: "";
    display: table;
    clear: both;
  }
 
  .user-bar-sms div {
    float: left;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
  }
 
  .user-bar-sms .actions {
    float: right;
    margin: 0 0 0 20px;
  }
 
  .user-bar-sms .actions.more {
    margin: 0 12px 0 32px;
  }
 
  .user-bar-sms .actions.attachment {
    margin: 0 0 0 30px;
  }
 
  .user-bar-sms .actions.attachment i {
    display: block;
    transform: rotate(-45deg);
  }
 
  .avatarWhat {
   height: 10px;
  }
 
  .user-bar-sms .avatar {
    margin: 0 0 0 5px;
    width: 36px;
    height: 36px;
  }
 
  .user-bar-sms .avatar img {
    border-radius: 50%;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
    display: block;
    width: 105%;
    height: inherit;   
    background-color: #fff;
  }
 
  .user-bar-sms .name {
    font-size: 17px;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 0 22px;
    overflow: hidden;
    white-space: nowrap;
    width: 110px;
  }
 
  .user-bar-sms .status {
    display: block;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
  }
 

    /* Status Bar */

    .conversation-compose .send .circle-sms {
      background: #F2AB08;
      border-radius: 50%;
      color: #fff;
      position: relative;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .status-bar-sms {
        height: 25px;
        background: #E89F2B;
        color: #fff;
        font-size: 14px;
        padding: 0 8px;
      }
      
      .status-bar-sms:after {
        content: "";
        display: table;
        clear: both;
      }
      
      .status-bar-sms div {
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 0 0 8px;
        font-weight: 600;
      }

      .conversation-sms {
        height: calc(100% - 12px);
        position: relative;
        background: white;
        z-index: 0;
      }
     

      .conversation-sms .conversation-container-sms {
        height: calc(100% - 68px);
        overflow-x: hidden;
        padding: 0 16px;
        margin-bottom: 5px;
        height: 310px;
      }