#img-user-info {
    border-radius: 30px;
    border-style: none;
    // height: 120px;
    vertical-align: baseline;
    // width: 120px;
    max-width: 100%;
}

html,
body,
.container-div {
    height: 100%;
    margin: 0;
    padding: 0;
}

.content {
    margin-top: 16px;
    padding: 0;
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
    /*-webkit-overflow-scrolling: touch;*/
}

#content-scroll-user-info {
    position: absolute;
    top: 26px;
    bottom: 0;
    left: 28px;
    right: 0;
    overflow: auto;
    height: 100%;
}

.space-top {
    margin-top: 24px;
}

.segment {
    padding: 4px;
    margin-bottom: 8px;
    height: 32;
}

.segment-inner {
    width: 100%;
    border-bottom: solid #e0e0e2 1px;
    padding-bottom: 8px;
    height: 32px;
    color: #888888;
}

.segment-inner-post {
    border-bottom: solid #e0e0e2 1px;
    height: 32px;
    input-security: auto;
}

.container-text {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

/* img {
    max-width: 100%;
    max-height: 120px;
} OJO ELIMINE POR AFECTAR AL BANNER EN SUS IMG */

.text-span {
    padding-right: 8px;
    vertical-align: middle;
    max-height: 36px;
}

.frame {
    white-space: nowrap;
    text-align: center;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.plan-card {
    padding: 0px;
}

.rounded-15 {
    border: 1px solid #E0E0E2;
    border-radius: 15px;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}

.title-bar {
    background: #00DA98;
    padding: 12px 0px 12px 16px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #232323;
}

.title-body {
    padding: 16px;
}

.title-body-principal {
    color: black;
    font-weight: 400;
}

.title-body-legend {
    font-weight: 300;
    font-style: italic;
}