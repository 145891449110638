@import 'global.scss';

.create-user-form {
    flex-grow: 3;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-left: 35%;
}

.error {
    border-color: #cc1d1d !important;
}

.color-label {
    color: $color-dark-grey;
}

.position-forms {
    margin-left: 100px;
}

.pink-color {
    color: $color-pink;
}

.space {
    margin-bottom: 25px !important;
}

.position-icon {
    position: absolute;
    right: 3%;
    top: 26px;
}

.far-icon {
    font-size: 20px;
    color: $color-dark-grey;
    cursor: pointer
}

.text-input {
    float: inline-end;
    font-size: 10px;
    font-weight: bolder;
    color: $color-red;
    display: block;
    // margin-left: 10px;
}

.longitud {
  width: 100%;
}

.container-position {
    color: $color-dark-grey;
    margin-left: 60px;
}

.position-alert {
    float:  inline-end;
    background-color: $color-grey;
    position: absolute;
    width: 160px;
    height: 100px;
    font-size: 12px;
    text-align: justify;
    left: 101%;
    top: 24px;
    color: #070606;
    padding: 5px;
    border-radius: 10px;
}

.container-user {
    margin-top: 50px;
}

.position-alert_pass {
    float:  inline-end;
    background-color: $color-grey;
    position: absolute;
    width: 160px;
    height: 100px;
    font-size: 12px;
    text-align: justify;
    left: 101%;
    top: 24px;
    color: #070606;
    padding: 5px;
    border-radius: 10px;
}
.container-positiones {
    color: $color-dark-grey;
    margin-left: 130px;
}

.form-group {
    margin-bottom: 0.7rem;
}

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 1px solid $color-dark-grey;
}

.text-condicion {
    width: auto;
    color: $color-dark-grey;
    text-align: center;
    margin-left: 2%;
    font-weight: 500;
    font-size: small;
}

.none-class {
    display: none;
}

.create-button {   
    color: #ffffff;
    background-color: $color-blue !important;
    border-color: $color-blue !important;
}

.createUserContainer {
    flex-grow: 1;
    z-index: inherit;
    display: flex;
    width: 1200px;
    font-size: 13px;
    margin-top: 50px;
}

.drop-down {
    border: none;
    width: -webkit-fill-available;
}

/* .error {
    border: 1px solid $color-red;
} */

.form-label-create-user {
    display: block;
    margin-bottom: 0.5rem;
}

@media (max-width: 1279px) {
    .position-forms {
        margin-left: 42px;
    }
    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 93px;
        height: 175px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 92px;
        height: 173px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 12px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }
    .col-md-11 {
        max-width: 80%;
    }

    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 27%;
    }
}

@media (max-width: 1200px) {
    .position-forms {
        margin-left: 42px;
    }
    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 93px;
        height: 175px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 92px;
        height: 173px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 12px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }
    .col-md-11 {
        max-width: 80%;
    }

    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 33%;
    }

    .loginbannerimage {
        height: 170px;
        width: auto;
        bottom: 20%;
        left: 28%;
        position: fixed;
        top: 48%;
    }
}

@media (max-width: 1100px) {
    .position-forms {
        margin-left: -70px;
    }
    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 93px;
        height: 175px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 92px;
        height: 173px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 12px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }
}

@media (max-width: 1000px) {
    .position-forms {
        margin-left: -110px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 93px;
        height: 175px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }
    .col-md-11 {
        max-width: 71%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 92px;
        height: 173px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 12px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }
}

@media (max-width: 980px) {
    .position-forms {
        margin-left: -130px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 93px;
        height: 175px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .col-md-11 {
        max-width: 88.666667%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 92px;
        height: 173px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 12px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }
}

@media (max-width: 940px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 13%;
    }

    .longitud {
        width: 100%;
    }

    .position-forms {
        margin-left: 92px;
    }

    .col-md-11 {
        max-width: 85.666667%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 93px;
        height: 175px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 92px;
        height: 173px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 12px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }


}

@media (max-width: 900px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 17%;
        width: 77%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 35%;
        overflow-y: scroll;
        height: 400px;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 820px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 18%;
        width: 71%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 48%;
        overflow-y: scroll;
        height: 390px;
        max-width: 89%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 689px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 12%;
        width: 83%;
    }
    .position-forms {
        margin-left: 5%;
    }
    .logo {
        position: absolute;
        left: 40%;
        top: 95%;
        bottom: 10%;
        z-index: 3;
    }

    #forms-user {
        margin-top: 41%;
        overflow-y: scroll;
        height: 400px;
        max-width: 91%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 650px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 10%;
        width: 83%;
    }
    .position-forms {
        margin-left: 5%;
    }

    .logo {
        position: absolute;
        left: 40%;
        top: 95%;
        bottom: 10%;
        z-index: 3;
    }

    #forms-user {
        margin-top: 54%;
        overflow-y: scroll;
        height: 380px;
        max-width: 93%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 580px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 10%;
        width: 83%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 58%;
        overflow-y: scroll;
        height: 380px;
        max-width: 93%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 540px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 15%;
        width: 83%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 64%;
        overflow-y: scroll;
        height: 380px;
        max-width: 93%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 480px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 15%;
        width: 83%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 59%;
        overflow-y: scroll;
        height: 410px;
        max-width: 95%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 430px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 3%;
        width: 95%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 59%;
        overflow-y: scroll;
        height: 410px;
        max-width: 100%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 418px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 0%;
        width: 120%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 52%;
        overflow-y: scroll;
        height: 410px;
        max-width: 85%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 19%;
        left: 36%;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 397px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 8%;
        width: 121%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 52%;
        overflow-y: scroll;
        height: 420px;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 375px) {
    .create-user-form {
        flex-grow: 3;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: 0%;
        width: 129%;
    }
    .position-forms {
        margin-left: 5%;
    }

    #forms-user {
        margin-top: 52%;
        overflow-y: scroll;
        height: 420px;
    }

    .position-alert {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 113px;
        height: 146px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 24px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .longitud {
        width: 100%;
    }

    .position-alert_pass {
        float: inline-end;
        background-color: #f1eaea;
        position: absolute;
        width: 100px;
        height: 151px;
        font-size: 12px;
        text-align: justify;
        left: 101%;
        top: 1px;
        color: #070606;
        padding: 5px;
        border-radius: 10px;
    }

    .footer {
        display: none;
    }
    

    // overflow-y: scroll;
}

@media only screen and (min-height:200px) and (max-height:237px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 0% !important;
        padding-top: 58%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 51%;
        left: 49%;
    }

    .logo {
        display: none;
    }

    #forms-user {
        margin-top: 57%;
        overflow-y: scroll;
        height: 220px;
    }

}

@media only screen and (min-height:237px) and (max-height:300px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 0% !important;
        padding-top: 58%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 51%;
        left: 49%;
    }

    .logo {
        display: none;
    }

    #forms-user {
        margin-top: 80%;
        overflow-y: scroll;
        height: 220px;
    }

}

@media only screen and (min-height:301px) and (max-height:342px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 44%;
        left: 36%;
    }
}

@media only screen and (min-height:343px) and (max-height:350px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 44%;
        left: 36%;
    }
    #forms-user {
        margin-top: 85%;
        overflow-y: scroll;
        height: 420px;
    }
}

@media only screen and (min-height:351px) and (max-height:390px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .loginbannerimage {
        position: absolute;
        width: 109px;
        height: 90px;
        top: 29%;
        left: 50%;
    }
    #forms-user {
        margin-top: 47%;
        overflow-y: scroll;
        height: 420px;
    }
}

@media only screen and (min-height:391px) and (max-height:490px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .loginbannerimage {
        position: absolute;
        width: 109px;
        height: 90px;
        top: 29%;
        left: 50%;
    }
    #forms-user {
        margin-top: 57%;
        overflow-y: scroll;
        height: 420px;
    }
}

#forms-user::-webkit-scrollbar
{
    width: 10px;
    background-color:$color-white;
}
 
#forms-user::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    box-shadow: inset 0 0 6px $color-white;
    background-color: $color-grey;
}