@import "global";

.campanaSteprow{
  justify-content: start !important;
  
}
.campanaStepcol{
  width: 70% !important;
}

.input_buscar {
  border: 1px solid;
  border-color: $color-grey;
  border-radius: 50px;
  box-sizing: border-box;
  height: 31px;
  width: 258.32px;
}

.campaing-tittle {
  color: $color-pink;
}

.btn_campania {
  background: $color-green;
  border-color: $color-green;
  border-radius: 100px;
  width: inherit;
}
#steps1-sc {
  height: 350px;
}
.bullhorn {
  color: $color-white;
}

.left2 {
  left: 1px;
  padding: 10px 1px 1px 60px;
  width: 300px;
}

.circle-color {
  border-color: $color-green;
}

.chevron-color {
  color: $color-pink;
  margin-left: 24px;
  margin-right: 24px;
}

.card-head {
  width: auto;
  height: 280px;
  background: $color-grey;
}

.panel_campania {
  border-top: 1px solid $color-grey;
  padding: 10px 5px 10px 5px;
}

.panel_campania_body {
  border-top: 1px solid $color-grey;
  padding: 30px 5px 20px 5px;
}

.buscar {
  text-align: right;
}

.btnsgt {
  float: right;
  width: 167px;
  height: 31px;
  background: $color-white;
  border: 1px solid $color-pink;
  border-radius: 50px;
  color: $color-pink;
  margin-bottom: 10px;
}

.btnatras {
  float: left;
  width: 167px;
  height: 31px;
  background: $color-white;
  border: 1px solid $color-pink;
  border-radius: 50px;
  color: $color-pink;
  margin-bottom: 10px;
}

.btn-posicionsgt {
  width: 50%;
  float: right;
  margin-bottom: 5%;
}

.circle-forma {
  border-radius: 50%;
  display: grid;
  background: $color-grey;
  width: 80px;
  height: 80px;
  color: $color-white;
  align-items: center;
  justify-items: center;
  font-size: 36px;
}

.circle-forma-color {
  background: $color-green;
}

.rectangu-color {
  border-color: $color-green;
}

.btn-sgt-step3 {
  margin-top: 70px;
}

.margen {
  margin-bottom: 13%;
}

.btn-posicionatras {
  width: 50%;
  float: left;
  margin-bottom: 5%;
}

.rectamgular-create {
  width: 220px;
  height: 390px;
  border: 1px solid $color-grey;
  border-radius: 5px;
}

.search-position {
  position: absolute;
  width: 79%;
  top: 220px;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 5;
  text-align: end;
}

.new-p-search {
  top: 410px;
}

.date-input {
  font-weight: bold;
}

.line-position {
  width: 99.8%;
  margin-left: 5px;
  margin-top: -25px;
}

.newPlantilla-position {
  max-width: 15.4%;
  border: 1px solid $color-grey;
  border-radius: 5px;
}
.newPlantilla-position-crear {
  border: 1px solid $color-grey;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.position-hr {
  position: relative;
}

.underlined {
  position: absolute;
  border-radius: 5px;
  border: 2px solid $color-pink;
  background: $color-pink;
  width: 100px;
  left: auto;
  top: 216px;
  margin-left: 5%;
}

.underlined-ajus2 {
  position: absolute;
  border-radius: 5px;
  border: 2px solid $color-pink;
  width: 100px;
  left: auto;
  top: 216px;
  background: $color-pink;
  margin-left: 22%;
}

.underlined-ajus3 {
  position: absolute;
  border-radius: 5px;
  border: 2px solid $color-pink;
  width: 100px;
  left: auto;
  top: 216px;
  margin-left: 40%;
  background: $color-pink;
}

.underlined-ajus4 {
  position: absolute;
  border-radius: 5px;
  border: 2px solid $color-pink;
  width: 100px;
  left: auto;
  top: 216px;
  margin-left: 57%;
  background: $color-pink;
}

.underlined-ajus5 {
  position: absolute;
  border-radius: 5px;
  border: 2px solid $color-pink;
  width: 100px;
  left: auto;
  top: 216px;
  margin-left: 71%;
  background: $color-pink;
}

.circle-crear {
  border-radius: 50%;
  display: grid;
  background: $color-grey;
  width: 150px;
  height: 150px;
  margin-top: 50%;
}

.form-control-sm {
  border-radius: 50px;
}

.search-camp-posi {
  position: absolute;
  display: flex;
}

.container-scroll {
  height: 320px;
  margin-bottom: 8px;
}

.scroll {
  overflow: auto;
  white-space: nowrap;
}

.scroll-sept1::-webkit-scrollbar {
  display: none;
}
.scroll-writh {
  width: 90%;
}

.circle-crear-plan {
  border-radius: 50%;
  display: grid;
  background: $color-grey;
  max-width: 65px;
  width: auto;
  height: auto;
  min-height: 65px;
  cursor: pointer;
  align-items: inherit;
}
.text-plantillas {
  color: #878787;
  width: 100%;
  align-items: center;
  justify-items: center;
  font-weight: bold;
  text-align: center;
}

.fas-plantilla {
  color: $color-white;
  font-size: 50px;
  margin-top: 30%;
  margin-left: 40%;
}
.fas-plantilla-crea {
  color: $color-white;
  font-size: 30px;
  position: relative;
}

.fa-suma {
  font-size: 20px;
  color: $color-grey;
  margin-top: -70px;
  margin-left: 85px;
}
.fa-suma-crea {
  font-size: 10px;
  color: $color-grey;
  position: absolute;
  left: 50%;
  top: 20%;
}
.btn-create-campania {
  color: $color-white;
  background: $color-pink;
}

.btn-left,
.btn-rigth {
  position: absolute;
}

.btn-left {
  left: 0px;
  top: 100px;
}

.btn-rigth {
  left: 195%;
  top: 100px;
}

.p-sgt,
.p-atras {
  height: 70px;
}

.step3-w {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 80px;
}

.p-programacion {
  margin-right: 50px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}

.btn-sgt-w {
  width: auto;
}

.total-user {
  position: absolute;
  left: 86%;
  top: 230px;
}

.p-create-camp {
  top: 80px;
  left: 74%;
}

.fa-style {
  font-size: 18px;
}

.step1-scroll {
  height: 355px;
}

.p-button-carou {
  position: absolute;
  z-index: 1;
  top: -50px;
}

.index-search {
  z-index: 1;
}

.card-step1 {
  min-width: 326px;
  max-width: 326px;
  min-height: 108.188px;
  max-height: 108.188px;
}
.dataTables_wrapper div.dataTables_length select,
div.dataTables_filter input {
  color: black;
  border-radius: 50px;
  border: 1px solid $color-grey;
}

.dataTables_wrapper {
  margin-bottom: 30px;
}

.dataTables_paginate {
  padding-right: 80px;
  padding-top: 15px;
}

.dataTables_paginate a {
  color: $color-black;
}

.dataTables_info,
.dataTables_paginate {
  color: $color-dark-grey;
}
.dataTables_paginate {
  text-align: center !important;
}
.paginate_button {
  padding-left: 6px;
  background: $color-white;
  border: solid 1px $color-dark-grey;
  margin-left: 5px;
  padding-right: 5px;
}

span a {
  font-size: initial;
  background: $color-white;
}

.dataTables_length,
.dataTables_info {
  position: absolute;
  background: $color-white;
}

.previous {
  border: 1px solid $color-dark-grey;
  border-radius: 2px;
  background: $color-white;
  margin-right: 5px;
  padding-right: 5px;
}

.current {
  border: 1px solid $color-pink;
  background: $color-pink;
  color: $color-white !important;
}

.next {
  border: 1px solid $color-dark-grey;
  border-radius: 2px;
  background: $color-white;
  margin-right: 5px;
  padding-right: 5px;
  margin-left: 5px;
}

.visti {
  visibility: hidden;
}

td:hover .visti {
  visibility: visible;
}

#page-setp2 {
  margin-top: 20px;
  margin-left: 10px;
  width: auto;
  height: 250px;
}

#conversation-step2 {
  height: 200px;
}

#chat-step2 {
  height: 250px;
}

#page-whatsaap {
  height: 330px;
}

.t-search {
  top: 440px;
}

.page-mail-step2 {
  margin-left: 1px !important;
  background: whitesmoke;
}

#user-bar-mail {
  height: 40px;
}

.plcarousel {
  padding-left: 15px;
}

.colorinput {
  border-color: $color-green;
}

.colorRed {
  border-color: red;
}

.po {
  top: -70px !important;
  left: -50px !important;
}

.scrollbar::-webkit-scrollbar
{
    width: 10px;
    background-color:$color-white;
}
 
.scrollbar::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    box-shadow: inset 0 0 6px $color-white;
    background-color: $color-grey;
}

.scroll-h-camp {
  height: 500px;
}

.p-left-ca {
  float: left;
  position: absolute;
  top: 250px;
  left: 180px;
  z-index: 5;
  border-radius: 50px;
  background-color: #dcd1d1;
}
.p-right-ca {
  float: right;
  position: absolute;
  top: 270px;
  right: 1%;
  z-index: 5;
  border-radius: 50px;
  background-color: #dcd1d1;
}

#scroll-step3 {
  height: 432.500px;
}
#tbody {
  height: 90px;
}

.btnScroll {
  border-radius: 50px;
  background: #beb8b8;
  width: 25px;
  height: 25px;
  float: left;
  text-align: center;
}
.size-fas {
  font-size: 15px;
}
.page-link {
  color: $color-black;
  line-height: 0.8;
  margin-bottom: 0;
}
.page-item.active .page-link {
  background-color: $color-pink;
  border-color: $color-pink;
}
/*@media (min-width: 600px) and (max-width: 990px) {
  .search-campaign {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}*/

.camp-step2-no-permiso p{
  text-align: center;
  font-size: 20px;
}
.camp-step2-no-permiso{
  width: 100%;
}