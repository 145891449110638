.segment {
    padding: 4px;
    margin-bottom: 8px;
    height: 32;
}

.segmentInner {
    width: 100%;
    border-bottom: solid #e0e0e2 1px;
    padding-bottom: 8px;
    height: 32px;
    color: #888888;
}