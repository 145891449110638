@import 'global.scss';

.label-input100 {
    font-size: 15px;
    line-height: 1.2;
    text-align: right;
    position: absolute;
    top: 14px;
    left: -105px;
    width: 80px;
}

input.input100 {
    height: 45px;
}

.fa-icon {
    color: $color-grey;
    font-size: 24px;
}
.input100 {
    font-size: 15px;
    color: $color-black;
    line-height: 1.2;
    display: block;
    width: 100%;
    background: transparent;
    padding: 0 5px;
}

input {
    outline: none;
    border: none;
}

button,
input {
    overflow: visible;
}

.container-bienvenida {
    margin-left: 500px !important;
    width: 337px !important;
}

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.footer-login {
    color: #848383 !important;
}
.position {
    margin-bottom: 0 !important;
    margin-left: 5px;
    background: $color-white;
}

.position-restore {
    margin-left: 40px;
    margin-top: 30px;
}

.color-restore {
    color: #848383;
    background: $color-white;
}

.position-create {
    margin-right: 35px !important;
    color: #848383;
}
.wrap-input100 {
    width: 93%;
    position: relative;
    border-bottom: 1px solid $color-grey;
}


p {
    color: $color-black;
    font-weight: 300;
}

.banner-subtitle {
    width: 235px;
}

.login:hover {
    color: $color-white;
    background-color: $color-blue;
    border-color: $color-blue;
}

.MuiTypography-h6 {
    margin: auto;
  }

.login0 {
    color: $color-white !important;
    background-color: $color-pink !Important;
    border-color: $color-pink !Important;
    width: 300px;
    flex-direction: column;
    flex: 1;
    text-align: center;
    align-content: center;
    alignment: center;
    align-items: center;
    display: flex;
}

.login1 {
    color: $color-white;
    background-color: $color-pink !Important;
    border-color: $color-pink !Important;
}

.login2 {
    color: $color-white;
    background-color: $color-pink !Important;
    border-color: $color-pink !Important;
}


.login-form {
    padding-top: 100px;
    margin: 0 auto;
    justify-content: center;
}

.content {
    padding: 5rem 0;
}

.login-indicator {
    position: absolute;
    right: 102px;
    top: 100px;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.text-left-login {
    color: $color-pink;
    font-weight: bold;
}

.carousel-inner img {
    width: 100%;
    height: 100%;
}

.justify-content-center {
    justify-content: left !important;
}

.content-left {
    padding-top: 150px;
    padding-right: 350px;
}

.img-top {
    position: absolute;
    top: 8px;
    left: 16px;
    font-size: 18px;
    width: 10%;
    height: auto;
}

.img-fluid {
    position: absolute;
    top: 290px;
    left: 370px;
    font-size: 18px;
    width: 280px;
    height: 210px;
}

.background0 {
    background: $color-yellow;
    transition: all 1000ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition-property: background-color;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
}

.background1 {
    background: $color-green;
    transition: all 1000ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition-property: background-color;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
}

.background2 {
    background: $color-pink;
    transition: all 1000ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition-property: background-color;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
}

.logo {
    margin: 5px 10px;
    position: absolute;
    left: 16px;
    font-size: 18px;
    width: 10%;
    height: auto;
}


/* Hay que cambiar las posiciones/tamaño de este circulo en caso de mobile */

.backgroundCircle {
    z-index: 0;
    background: $color-white;
    width: 100%;
    height: 100%;
    clip-path: circle(75% at 100% 50%);
    position: absolute;
}

.loginContainer {
    flex-grow: 1;
    z-index: inherit;
    display: flex;
    width: 90%;
    margin-top: 100px;
    flex-wrap: nowrap;
    align-items: center;
}

.infoContainer {
    flex-grow: 2;
    display: flex;
    align-items: center;
}

.login-form {
    flex-grow: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-left: 5%
}

.loginButton {
    background-color: $color-pink;
    transition: all 1000ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition-property: background-color;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 21px;
}

.fa {
    color: $color-black;
}


.loginbannerimage {
    top: 37%;
    left: 27%;
    position: fixed;
    //width: 500px;
    //height: 500px;
  }

  .registro {
    justify-content: center;
    align-items: baseline;
  }

  .pass {
    justify-content: center;
    display: flex;
  }

// css responsive any devices

/* @media (min-width: 1076px) and (max-width: 1240px) {
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: auto;
    }

    .loginbannerimage {
        height: 300px;
        width: auto;
        bottom: 10%;
        left: 38%;
        position: fixed;
    }

    .backgroundCircle {
        z-index: 0;
        background: $color-white;
        width: 113%;
        height: 100%;
        clip-path: circle(75% at 100% 50%);
        position: absolute;
    }
}

@media (min-width: 970px) and (max-width: 1076px) {
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: auto;
    }

    .loginbannerimage {
        height: 200px;
        width: auto;
        bottom: 20%;
        left: 38%;
        position: fixed;
    }

    .backgroundCircle {
        z-index: 0;
        background: $color-white;
        width: 113%;
        height: 100%;
        clip-path: circle(75% at 100% 50%);
        position: absolute;
    }
}

@media (min-width: 855px) and (max-width: 970px) {
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: auto;
        margin-right: 300px;
    }

    .loginbannerimage {
        height: 200px;
        width: auto;
        bottom: 10%;
        left: 38%;
        position: fixed;
    }

    .backgroundCircle {
        z-index: 0;
        background: $color-white;
        width: 125%;
        height: 100%;
        clip-path: circle(75% at 100% 50%);
        position: absolute;
    }
}

@media (min-width: 750px) and (max-width: 855px) {
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -10px;
        margin-right: 470px;
    }

    .loginbannerimage {
        height: 200px;
        width: auto;
        bottom: 10%;
        left: 27%;
        position: fixed;
    }

    .backgroundCircle {
        z-index: 0;
        background: $color-white;
        width: 125%;
        height: 100%;
        clip-path: circle(75% at 100% 50%);
        position: absolute;
    }

    .container {
        height: 100%;
        display: flex;
        flex-direction: inherit;
        z-index: 1;
        margin-left: 40px;
    }
}
@media (min-width:600px) and (max-width: 750px){
    .infoContainer {
        flex-grow: 2;
        display: table-caption;
        align-items: center;
        margin-top: 75px;
        margin-bottom: -90px;
        margin-left: 25px;
    }
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-top: 130px;
        margin-left: -450px;
        margin-right: 470px;
    }
    .loginbannerimage {
        height: 200px;
        width: auto;
        bottom: 5%;
        left: auto;
        position: fixed;
    }
}
@media (min-width: 455px) and (max-width: 600px) {
    .infoContainer {
        flex-grow: 2;
        display: table-caption;
        align-items: center;
        margin-top: 90px;
        margin-bottom: -90px;
        margin-left: 25px;
    }
    .container {
        height: 100%;
        display: flex;
        flex-direction: inherit;
        z-index: 1;
        margin-left: 40px;
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-top: 130px;
        margin-left: -630px;
        margin-right: 470px;
    }

    .loginbannerimage {
        height: 200px;
        width: auto;
        bottom: 5%;
        left: 27%;
        position: fixed;
    }

    .backgroundCircle {
        z-index: 0;
        background: $color-white;
        width: 125%;
        height: 100%;
        clip-path: circle(75% at 100% 50%);
        position: absolute;
    }
}
// css responsive any devices
*/
// css mobile

@media (max-width: 1290px) {
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: flex;
        justify-content: end;
        align-items: center;
        z-index: 2;
        margin-left: auto;
        padding-top: 20%;
    }

    .loginbannerimage {
        height: 200px;
        width: auto;
        bottom: 20%;
        left: 28%;
        position: fixed;
        top: 48%;
    }

    .backgroundCircle {
        z-index: 0;
        background: $color-white;
        transition: all 1000ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
        transition-property: background-color;
        width: 113%;
        height: 100%;
        clip-path: circle(75% at 100% 50%);
        position: absolute;
    }

    .loginContainer {
        flex-grow: 1;
        z-index: inherit;
        display: flex;
        width: auto;
        margin-top: 5%;
        flex-wrap: nowrap;
    }
    .container-bienvenida {
        margin-left: 5% !important;
        width: 337px !important;
    }

    .infoContainer {
        flex-grow: 2;
        margin-top: 0;
        margin-bottom: -15px;
        margin-left: 25px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        align-items: center;
        left: 5%;
        right: 32.27%;
        top: 5%;
        bottom: 89.04%;
    }
}

@media  only screen and (min-width: 1015px) and (max-width:1290px) {
    .loginbannerimage {
        height: 250px;
        width: auto;
        bottom: 20%;
        left: 28%;
        position: fixed;
        top: 48%;
    }
}


@media (max-width: 1024px) {
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: flex;
        justify-content: end;
        align-items: center;
        z-index: 2;
        margin-left: auto;
        padding-top: 20%;
    }

    .loginbannerimage {
        height: 200px;
        width: auto;
        bottom: 20%;
        left: 28%;
        position: fixed;
        top: 48%;
    }

    .backgroundCircle {
        z-index: 0;
        background: $color-white;
        width: 113%;
        height: 100%;
        clip-path: circle(75% at 100% 50%);
        position: absolute;
    }

    .loginContainer {
        flex-grow: 1;
        z-index: inherit;
        display: flex;
        width: auto;
        margin-top: 5%;
        flex-wrap: nowrap;
    }
    .container-bienvenida {
        margin-left: 5% !important;
        width: 337px !important;
    }

    .infoContainer {
        flex-grow: 2;
        margin-top: 0;
        margin-bottom: -15px;
        margin-left: 25px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        align-items: center;
        left: 5%;
        right: 32.27%;
        top: 5%;
        bottom: 89.04%;
    }
}

@media (max-width:900px) {
    .container {
        height: 100%;
        display: flex;
        flex-direction: inherit;
        z-index: 1;
        margin-left: 25px;
    }

    .mb4 {
        margin-bottom: 0px !important;
    }

    .infoContainer {
        flex-grow: 2;
        margin-top: 0;
        margin-bottom: -69px;
        margin-left: 25px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        align-items: center;
        left: 5%;
        right: 32.27%;
        top: 5%;
        bottom: 89.04%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 18%;
        left: 40%;
    }

    #logoN {
        width: 120px;
        height: 40px;
    }

    .container-bienvenida {
        margin-left: 7% !important;
        width: 60% !important;
        line-height: 15px;
    }

    .position-create {
        margin-right: 0px !important;
    }

    .btn2 {
        width: 100% !important;
    }
    .btn0 {
        width: 100% !important;
    }
    .btn1 {
        width: 100% !important;
    }

    .footer-login {
        display: none;
    }

    .position-restore {
        float: left;
        margin-top: 15px;
        margin-bottom: 1%;
        margin-left: 20%;
    }

    .form-inline {
        float: left !important;
        margin-left: 20% !important;
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 6%;
        padding-top: 30%;
    }


    .loginContainer {
        flex-grow: 1;
        z-index: inherit;
        display: block;
        width: auto;
        margin-top: 5%;
    }
}

@media  only screen and (min-width: 801px) and (max-width:900px) {
    .backgroundCircle {
        width: 100%;
        height: 100%;
        clip-path: circle(70% at 50% 100%)

    }
    .container-bienvenida {
        margin-left: 7% !important;
        width: 50% !important;
        line-height: 15px;
    }
}

@media  only screen and (min-width: 710px) and (max-width:800px) {
    .backgroundCircle {
        width: 100%;
        height: 100%;
        clip-path: circle(70% at 50% 100%)

    }
}

@media  only screen and (min-width: 690px) and (max-width:709px) {
    .backgroundCircle {
        width: 100%;
        height: 100%;
        clip-path: circle(70% at 50% 100%)

    }
}

@media  only screen and (min-width: 600px) and (max-width:689px){
    .backgroundCircle {
        width: 100%;
        height: 100%;
        clip-path: circle(70% at 50% 95%)

    }
}

@media (max-width:600px) {
    .container {
        height: 100%;
        display: flex;
        flex-direction: inherit;
        z-index: 1;
        margin-left: 25px;
    }

    .mb4 {
        margin-bottom: 0px !important;
    }

    .infoContainer {
        flex-grow: 2;
        margin-top: 0;
        margin-bottom: -90px;
        margin-left: 25px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        align-items: center;
        left: 5%;
        right: 32.27%;
        top: 5%;
        bottom: 89.04%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 22%;
        left: 40%;
    }

    #logoN {
        width: 120px;
        height: 40px;
    }

    .container-bienvenida {
        margin-left: 7% !important;
        width: 65% !important;
        line-height: 15px;
    }

    .position-create {
        margin-right: 0px !important;
    }

    .btn2 {
        width: 100% !important;
    }
    .btn0 {
        width: 100% !important;
    }
    .btn1 {
        width: 100% !important;
    }

    .footer-login {
        display: none;
    }

    .position-restore {
        float: left;
        margin-top: 15px;
    }

    .form-inline {
        float: left !important;
        margin-left: 20% !important;
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 21%;
        padding-top: 40%;
    }


    .loginContainer {
        flex-grow: 1;
        z-index: inherit;
        display: block;
        width: auto;
        margin-top: 5%;
    }

    .backgroundCircle {
        width: 100%;
        height: 100%;
        clip-path: circle(70% at 50% 95%)

    }
}

@media (max-width:480px) {
    .container {
        height: 100%;
        display: flex;
        flex-direction: inherit;
        z-index: 1;
        margin-left: 25px;
    }

    .infoContainer {
        flex-grow: 2;
        margin-top: 0;
        margin-bottom: -90px;
        margin-left: 25px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        align-items: center;
        left: 5%;
        right: 32.27%;
        top: 5%;
        bottom: 89.04%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 22%;
        left: 40%;
    }

    #logoN {
        width: 120px;
        height: 40px;
    }

    .container-bienvenida {
        margin-left: 7% !important;
        width: 85% !important;
        line-height: 15px;
    }

    .position-create {
        margin-right: 0px !important;
    }

    .btn2 {
        width: 100% !important;
    }
    .btn0 {
        width: 100% !important;
    }
    .btn1 {
        width: 100% !important;
    }

    .footer-login {
        display: none;
    }

    .position-restore {
        float: right;
        margin-top: 15px;
    }

    .form-inline {
        float: right;
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 35%;
        padding-top: 60%;
    }


    .loginContainer {
        flex-grow: 1;
        z-index: inherit;
        display: block;
        width: auto;
        margin-top: 5%;
    }

    .backgroundCircle {
        width: 100%;
        height: 100%;
        clip-path: circle(70% at 50% 95%)

    }
}

@media  only screen and (min-width: 360px) and (max-width:480px) {
    .container {
        height: 100%;
        display: flex;
        flex-direction: inherit;
        z-index: 1;
        margin-left: 25px;
    }

    .infoContainer {
        flex-grow: 2;
        margin-top: 0;
        margin-bottom: -90px;
        margin-left: 25px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        align-items: center;
        left: 5%;
        right: 32.27%;
        top: 5%;
        bottom: 89.04%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 22%;
        left: 30%;
    }

    #logoN {
        width: 120px;
        height: 40px;
    }

    .container-bienvenida {
        margin-left: 7% !important;
        width: 85% !important;
        line-height: 15px;
    }

    .position-create {
        margin-right: 0px !important;
    }

    .btn2 {
        width: 100% !important;
    }
    .btn0 {
        width: 100% !important;
    }
    .btn1 {
        width: 100% !important;
    }

    .footer-login {
        display: none;
    }

    .position-restore {
        margin-left: 10px;
        margin-top: 15px;
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 30%;
        padding-top: 50%;
    }


    .loginContainer {
        flex-grow: 1;
        z-index: inherit;
        display: block;
        width: auto;
        margin-top: 5%;
    }

    .backgroundCircle {
        width: 100%;
        height: 100%;
        clip-path: circle(50% at 50% 70%);

    }
}

@media (max-width:359px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }

    .backgroundCircle {
        height: 110%;
        clip-path: circle(102% at 50% 95%);
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 15%;
        left: 36%;
    }
    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 14%;
        padding-top: 60%;
    }

    .position-restore {
        float: left;
        margin-top: 15px;
        margin-bottom: 1%;
        margin-left: 0%;
    }
    .logo {
        position: absolute;
        left: 31%;
        top: 84%;
        bottom: 10%;
        z-index: 3;
    }


}

@media only screen and (min-height:200px) and (max-height:244px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .backgroundCircle {
        height: 250%;
        clip-path: circle(76% at 48% 91%);
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 0% !important;
        padding-top: 58%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 32%;
        left: 49%;
    }

    .logo {
        display: none;
    }

}

@media only screen and (min-height:245px) and (max-height:300px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .backgroundCircle {
        height: 250%;
        clip-path: circle(86% at 48% 91%);
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 0% !important;
        padding-top: 58%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 32%;
        left: 49%;
    }

    .logo {
        display: none;
    }

}

@media only screen and (min-height:301px) and (max-height:350px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .backgroundCircle {
        height: 250%;
        clip-path: circle(86% at 48% 91%);
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 15% !important;
        padding-top: 58%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 32%;
        left: 49%;
    }

    .logo {
        display: none;
    }

}

@media only screen and (min-height:351px) and (max-height:450px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .backgroundCircle {
        height: 180%;
        clip-path: circle(86% at 48% 91%);
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 15% !important;
        padding-top: 58%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 32%;
        left: 34%;
    }

    .logo {
        display: none;
    }

}


@media only screen and (min-height:449px) and (max-height:530px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }
    .backgroundCircle {
        height: 171%;
        clip-path: circle(102% at 50% 95%);
    }

    .login-form {
        margin: auto;
        flex-grow: 3;
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-left: -12px;
        margin-right: 10px;
        margin-top: 0%;
        padding-top: 58%;
    }

    .loginbannerimage {
        position: absolute;
        width: 110px;
        height: 110px;
        top: 23%;
        left: 42%;
    }

    .logo {
        display: none;
    }

}

@media only screen and (min-height:531px) and (max-height:720px) {
    .background1, .background0, .background2  {
        height: 110%;
        overflow-y: scroll;
    }


 }


 .background0::-webkit-scrollbar , .background1::-webkit-scrollbar, .background2::-webkit-scrollbar
{
    width: 10px;
    background-color:$color-white;
}

.background0::-webkit-scrollbar-thumb, .background1::-webkit-scrollbar-thumb, .background2::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    box-shadow: inset 0 0 6px $color-white;
    background-color: $color-grey;
}
