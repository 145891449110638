@import 'global';

.backgroundRestablecer {
  background: $color-green;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

.logo {
  margin: 5px 1px;
}

.create-acceder {
  background: $color-pink;
  width: 166px;
  height: 36px;
  margin-left: 120px;
  color: $color-white;
}

.text-restablecer {
  width: 307px;
}

.background-circle-restablecer {
  background: $color-white;
  clip-path: circle(230px at 230px 230px);
  height: 480px;
  width: 460px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
  
}

.login-restablecer {
  align-items: center;
  justify-content: center;
}

h5, p {
  font-family: $font-family;
}

.login-restablecer form {
  left: 7%;
  position: absolute;
  text-align: center;
  top: 31%;
  width: 370px;
}
.cheque-eviado {
  background: $color-green;
  border: 1px;
  bottom: 4.17%;
  clip-path: circle(24px at 24px 24px);
  width: 70px;
  margin-left: 40%;
  margin-bottom: 10px;
}

.visto {
  font-size: 26px;
  line-height: 40px;
  color: $color-white;
  align-items: center;
  margin-top: 10px;
  margin-right: 25px;

}

  