@import 'global';

.background1 {
  background: $color-green;
}

.logo {
  margin: 5px 10px;
}

.background-circle-forgot {
  background: $color-white;
  clip-path: circle(230px at 230px 230px);
  height: 480px;
  width: 460px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.btn-send { 
  margin-left: 0rem !important;
}

.login-forgot-password {
  align-items: center;
  justify-content: center;
}

.login-forgot-password form {
  text-align: center;
  width: 370px;
  width: 400px;
  margin: 0 auto;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
  
}

.cheque {
  align-items: center;
  background: $color-pink;
  border: 1px;
  bottom: 4.17%;
  clip-path: circle(19px at 19px 19px);
  color: $color-white;
  font-size: 26px;
  height: 39px;
  left: 4.17%;
  line-height: 40px;
  right: 4.17%;
  top: 4.17%;
  width: 39px;
}

.envio {
  margin-top: 8px;
  margin-left: 100px;
  font-weight: bold;
}

.btn-enviado {
  align-items: center;
  background: $color-green;
  border: 1px;
  bottom: 4.17%;
  clip-path: circle(19px at 19px 19px);
  color: $color-white;
  height: 39px;
  left: 4.17%;
  line-height: 40px;
  right: 4.17%;
  top: 4.17%;
  width: 39px;
}

.no-success {
  display: none;
}

.return_password {
  bottom: 5%;
  height: 35px;
  left: 1px;
  margin: 3px 3px 1px 1px;
  padding: 10px 30px;
  position: absolute;
}

.faas {
  color: $color-white;
  font-size: 30px;
}


@media (min-width: 1040px) and (max-width: 1200px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 290px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 405px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 990px) and (max-width: 1040px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 220px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 335px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }

  .sheet-next-last {
    background: $color-yellow;
    border-radius: 8px;
    height: 8px;
    left: 90px;
    margin-left: 42px;
    margin-top: 90px;
    top: 70px;
    width: 800px;
  }
}

@media (min-width: 870px) and (max-width: 990px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 200px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 320px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 670px) and (max-width: 870px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 150px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 265px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

.flex-shrink {
  margin-top: -8px;
}

@media (min-width: 638px) and (max-width: 738px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 80px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 195px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 548px) and (max-width: 638px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: 30px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 145px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 450px) and (max-width: 548px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: -20px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 100px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (min-width: 150px) and (max-width: 450px) {
  .background-circle-forgot {
    background: $color-white;
    clip-path: circle(200px at 300px 230px);
    height: 480px;
    left: -92px;
    position: absolute;
    top: 100px;
    width: 580px;
 }

  .login-forgot-password form {
    left: 30px;
    position: absolute;
    text-align: center;
    top: 190px;
    width: 370px;
  }
}

@media (max-width: 450px) {
  .background-circle-forgot {
    background: #ffffff;
    clip-path: circle(194px at 230px 230px) !important;
    height: 480px;
    width: 460px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .form-group, #errorTipoCanal {
    text-align: left;
    width: 81%;
  }
}

@media (max-width: 360px) {
  .background-circle-forgot {
    background: #ffffff;
    clip-path: circle(177px at 200px 230px) !important;    height: 480px;
    width: 396px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .login-forgot-password form {
    text-align: center;
    width: 370px !important;
    margin: 0 auto;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  p {
    font-size: 11px !important;
  }
}