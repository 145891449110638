/* Button Styles */

.custom-button-square {
    padding: 25px 16px 25px 16px !important;
    border-radius: 8px !important;
}

.custom-button-circle {
    /* padding: 25px 16px 25px 16px !important; */
    /* border-radius: 50% !important; */
  position: relative;
  display: inline-block;
  margin: 1em;
  padding: 1em 2em;
  border: 0;
  border-radius: 70px !important;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #739600;
  /* color: #739600; */
}

/* Button Sizes */
.custom-button-small {
    padding: 5px 10px 5px 10px !important;
}

.custom-button-medium {
    padding: 10px 20px 10px 20px !important;
}

.custom-button-large {
    padding: 20px 30px 20px 30px !important;
}

.custom-button-xlarge {
    padding: 30px 40px 30px 40px !important;
}

.custom-button {
    border: none;
    border-radius: 5px;
    color: white;
    width: fit-content;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px 10px 20px;
    box-shadow: 2px 2px 6px grey;
    transition: .25s;
    overflow: hidden;
    position: relative;
}

.custom-button:after {
    content: "";
    background: lightgray;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.custom-button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

/* Button Colors */
.custom-button-primary {
    background-color: #9c12ae;
}

.custom-button-accent {
    background-color: #3cba54;
}

/* Button Effects */
.button-container button.custom-button.blue:hover {
    cursor: pointer;
    background-color: #2b5294;
    box-shadow: 2px 2px 20px grey;
}

.button-container button.custom-button.green:hover {
    cursor: pointer;
    background-color: #298039;
    box-shadow: 2px 2px 20px grey;
}

.button-container button.custom-button.red:hover {
    cursor: pointer;
    background-color: #9e2427;
    box-shadow: 2px 2px 20px grey;
}