@import 'global';

.input-buscar {
  border: 1px solid;
  border-color: $color-grey;
  border-radius: 50px;
  box-sizing: border-box;
  height: 31px;
  width: 258.32px;
}

.campaing-tittle {
  color: $color-pink;
}

.btn-campania {
  background: $color-green;
  border-color: $color-green;
  border-radius: 100px;
  width: inherit;
  color: $color-white;
}

.bullhorn {
  color: $color-white;
}

.right  {
  padding: 10px 1px 1px 60px;
  position: absolute;
  right: 1px;
  width: 300px;
}

.right2  {
  padding: 10px 1px 1px 60px;
  right: 1px;
  width: 300px;
  text-align: end;
  margin-right: 30px;
}

.contenedor-btn-caampaign {
  margin-bottom: 70px;
}

.w-check {
  width: 20px;
  height: 20px;
  border-color: $color-grey;
}

.line {
  border-bottom: 1px;
  color: black;
  width: 5px;
  height: 2px;
}

.left2  {
  left: 1px;
  padding: 10px 1px 1px 60px;
  width: 300px;
}


.panel-campania {
  border-top: 1px solid $color-grey;
  padding: 10px 5px 10px 5px;
}

.panel-campania-body {
  border-top: 1px solid $color-grey;
  padding: 30px 5px 20px 5px;
}

.buscar {
  text-align: right;
}

.rectangular-campaign2 {
  border: 1px solid $color-grey;
  border-radius: 5px;
  box-sizing: border-box;
  min-height: 130px;
  max-height: auto;
  min-width: 100px;
  max-width: 350px;
  width: auto;
  margin-right: 4%;
}

.p-text {
  padding-top: 7px;
}

.panel-campania2 {
  border-top: 1px solid $color-grey;
  margin: 2px 3px 4px 5px;
  padding: 20px;
}

.circle-camp2 {
  background: $color-white;
  border: 4px solid $color-grey;
  border-radius: 50%;
  display: grid;
  height: 40px;
  justify-items: center;
  width: 40px;
}

.circle-posicion {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  float: left;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.fas-camp {
  color: $color-grey;
  font-size: 15px;
  margin-left: 24px;
  margin-right: 24px;
}

.form-control:focus {
  border-color: $color-green;
  box-shadow: 0 0 0 0.2rem rgba(137, 238, 112, 0.25);
}

.container-datepicker {
  border: 1.5px solid $color-grey;
  border-radius: 5px;
  height: auto;
}

.h-container {
  height: 250px;
}

th {
  text-align: center !important;
}

.btn-struct {
  width: 174.618px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  height: 36px;
}

.fa-file-excel {
  font-size: 25px;
  color: #E0E0E2;
}

.btnEXCEL {
  border: 2px solid #E0E0E2;
  width: 40px;
  padding: 5px;
  border-radius: 10px;
}

.fa-arrow-down {
  color: #E0E0E2;
  right: 2%;
  top: 1px;
  position: absolute;
  font-size: small;
}
/*@media (min-width: 600px) and (max-width: 990px) {
  .search-campaign {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}*/