.office-banner {
    background-color: #e2ddd8;
    font-family: "Arial";
    padding: 0.125em;
    font-size: 4em;
    text-align: center;
    white-space: nowrap;
    transform: rotate(-10deg);
    position: fixed;
    top: 40%;
    left: -5%;
    right: -5%;
    z-index: 100;
    margin-top: 0;
  }
  