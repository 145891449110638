@import 'global';

.navbar-brand-second {
  font-size: 18px;
}

.sub-header-menu {
  position: fixed;
  right: 10px;
  left: 300px;
  width: -webkit-fill-available;
  background-color:#FFF ;
}

.sub-body{
  top: 130px;
}

.title-template {
  font-size: 16px;
    font-weight: bold;
    padding-right: 30px;
}

.btn_dash {
  background: #00DA98;
  border-color: #00DA98 !important;
  border-radius: 100px !important;
  width: inherit !important;
  margin-top: 1px;
  margin-top: -10px;
}

.vertical {
  border-left: 1px solid #dddcdc;
  height: 40px;
}

.btn_template {
  background: #00DA98;
  border-color: #00DA98;
  border-radius: 100px;
  width: inherit;
}

.class-icon {
  margin-right: 20px;
}

.fa-chevron-down {
  margin-left: 10PX;
}

.form-control.input-select {
  font-size: 17px !important;
}

.top-indicator {
  margin-top: 20px;
}

.panel-title {
  border-right: 12px;
  padding-bottom: 2px;
  padding-right: -50px;
}

.navbar-nav-second {
  display: -webkit-inline-box;
  flex-direction: column;
  list-style: none;
  margin-bottom: 10;
}

.fa-color {
  color: $color-dark-grey;
}

.navbar-nav {
  width: 100%;
}

.templ {
  width: 100%;
  height: auto;
}

.type-select {
  border-radius: 5px;
  border: 2px solid #FFC700;
  background: #FFC700;
}

.dms {
  position: absolut !important;
  transform: translate3d(-65px, 22px, 0px) !important;
  top: -150px !important;
  left: 85px !important;
  will-change: transform !important;
  z-index: 1 !important;
  font-size: 15px !important;
}

.dms2 {
  transform: translate3d(-65px, 22px, 0px) !important;
  top: -55px !important;
  left: 110px !important;
  will-change: transform !important;
  z-index: 1 !important;
  font-size: 15px !important;
}

.center-h-v {
  background-color: #ccc;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	margin-left: -55px;
  border-radius: 50px;
}

.center-dw {
  background-color: #ccc;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	margin-left: -55px;
}

.dropdown {
  text-align: end;
}

.menu-1 {
  padding: 6px 25px 1px 12px !important;
  text-align: center !important;
  font-size: 14px !important;
  margin-left: -85px !important;
}

.menu-2 {
  padding: 6px 25px 1px 12px;
  text-align: center;
  font-size: 14px;
  margin-top: 7px !important;
}

.box-btn-template{
  box-sizing: border-box;
  border: 3px solid transparent;
  background-clip:padding-box;
}

.float-right-cbo {
  position: absolute;
  right: 1px;
  text-align: right;
}

.input-buscar-template {
  border: 1px solid;
  /* border-color: #f1eaea; */
  border-radius: 40px;
  box-sizing: border-box;
  height: 31px;
  width: 220px;
  
}

.disabled-a {
  cursor: pointer !important;
}

.container p {
  color: rgb(0, 0, 0) !important;
  text-align: center !important;
}


.input-a {
  font-size: 14px;
}

.input-select.input-select-size {
  font-size: 14px !important;
}

.order-by {
  margin-top: 5px !important;
}

.right-cbo {
  position: absolute;
  right: 0px;
  width: 300px;
  border: 3px solid #73AD21;
  padding: 10px;
}


.input-buscar-template::placeholder {
  margin-left: 10px;
}

.btn-primary-search {
  color: #fff;
  background-color: #00DA98;
  border-color: #99a7b7;
}

.float-left-btn {
    position: absolute;
    right: 1px;
    text-align: right;
    cursor: pointer;
    z-index: 1;
    border-radius: 50%;
}

.nav-link-second {
  padding-bottom: 10px; 
}

.v-line {
  background-color: $color-dark-grey;
  width: 1px;
  height: 20px;
}

.sub-color-template {
  text-align: end;
  width: 100px;
  border: 2px solid #ff4197;
  border-radius: 10px;
  background: #ff4197;
  margin-right: -3%;
}

.sub-color2-template {
  text-align: end;
  width: 105px;
  border: 2px solid #ff4197;
  border-radius: 10px;
  background: #ff4197;
  margin-left: -1%;
}

.underline {
  text-decoration: underline;
}

.btnAcep {
  width: 167px;
  background: $color-notify;
  border: 1px solid $color-pink;
  border-radius: 50px;
  color: $color-white;
  margin-bottom: 10px;
}

.btnClear {
  width: 167px;
  background: lightblue;
  border: 1px solid black;
  border-radius: 50px;
  color: black;
  margin-bottom: 10px;
}

.form-inline-div {
  float: right;
  margin: auto;
}

.btnCancel {
  width: 167px;
  background: $color-white;
  border: 1px solid $color-pink;
  border-radius: 50px;
  color: $color-notify;
  margin-bottom: 10px;
}

div.upload {
  background-color:#fff;
  border: 1px solid #ddd;
  border-radius:5px;
  display:inline-block;
  height: 30px;
  padding:3px 40px 3px 3px;
  position:relative;
  width: auto;
}

div.upload:hover {
  opacity:0.95;
}

div.upload input[type="file"] {
  display: input-block;
  width: 100%;
  height: 30px;
  opacity: 0;
  cursor:pointer;
  position:absolute;
  left:0;
}

.uploadButton {
  background-color: #425F9C;
  border: none;
  border-radius: 3px;
  color: #FFF;
  cursor:pointer;
  display: inline-block;
  margin-right:15px;
  width: auto;
  padding:0 20px;
  box-sizing: content-box;
}

.fileName {
  font-family: Arial;
  font-size:14px;
}

.upload + .uploadButton {
  height:38px;
} 

#editor-container {
  height: 500px;
}

.img-templates {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.btnDelete {
  width: 167px;
  height: 31px;
  background: $color-notify;
  border: 1px solid $color-pink;
  border-radius: 50px;
  color: $color-white;
  margin-bottom: 10px;
}

.file-upload {
  background-color: #ffffff;
  margin: 0 auto;
  margin-bottom: 60px
}

.progress-bar {
  width: 100%
}

.brand {
  display: none important;
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1FB264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #15824B;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #1AA059;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.cortar{
    width:200px;
    text-overflow:ellipsis;
    white-space:nowrap; 
    overflow:hidden; 
  }

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

/* .align-end-btn {
  text-align: left;
  /* position: absolute; 
  /* top: 170px; 
  right: -200px;
  font-size: 18px;
  /* width: 55%;
} */

.container-view-html
{
  display: contents;
  flex-direction: row-reverse;
  height: 100%;
  margin-left: 45px;
  z-index: 1;
}

.image-upload-wrap {
  border: 4px dashed $color-dark-grey;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: $color-grey;
  border: 4px dashed $color-dark-grey;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #15824B;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all .2s ease;
}

.text-html {
  height: 517px;
  width: 100%;
  border: 0px;
}

.btn-position-save {
  position: absolute;
  z-index: 1400;
}


@media (max-width: 576px) {
  .nav-item {
      width: 100%;
      text-align: left;
  }

  .v-line {
      display: none;
  }
}
.delete-img{
  position: absolute;
  right: 8%;
  top: 3%;
  color: #ff4197;
  display: none;
}

.conten {
  max-width: 900px !important;
}
.image:hover{
  .delete-img{
    display: block;
  }
}
.image{
  margin-bottom: 3%;
}

.slide {
  z-index: 1;
}