
.plan-card {
    padding: 0px;
}

.rounded-15 {
    border: 1px solid #E0E0E2;
    border-radius: 15px;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}

.title-bar {
    background: #00DA98;
    padding: 12px 0px 12px 16px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #232323;
}

.title-body {
    padding: 16px;
}

.title-body-principal {
    color: black;
    font-weight: 400;
}

.title-body-legend {
    font-weight: 300;
    font-style: italic;
}